<template>
    <v-card :loading="reserve_IsLoading">
      <v-container>
        <v-row justify="space-between">
          <v-card-title>
            {{ calendarSettings.selectedDesk.podLocation }}
          </v-card-title>
          <v-icon class="btns" @click="$emit('closeDialog')">mdi-close</v-icon>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-row no-gutters justify="center">
              <v-col>
                <v-menu
                  v-model="reservationStartInput"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDate"
                      label="Reservation Start Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    :min="minDate"
                    @input="reservationStartInput = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="reservationEndInput"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDate"
                      label="Reservation End Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    :min="endDateMin"
                    @input="reservationEndInput = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>


            <v-row class="reservationPresets" justify="center">
              <v-checkbox
                class="presetButton"
                v-model="isAllDay"
                label="All Day Reservation"
                @change="PresetTimeSelected('AllDay')"
              ></v-checkbox>

              <v-checkbox
                class="presetButton"
                v-model="isMorningOnly"
                label="Morning Reservation"
                @change="PresetTimeSelected('Morning')"
              ></v-checkbox>

              <v-checkbox
                class="presetButton"
                v-model="isAfternoonOnly"
                label="Afternoon Reservation"
                @change="PresetTimeSelected('Afternoon')"
              ></v-checkbox>
              <v-container>
                <v-btn
                  class="btns"
                  color="primary"
                  :disabled="!isFormFilledOut"
                  @click="ReserveRoom()"
                  >Reserve Desk</v-btn
                >
                <v-btn
                  class="btns"
                  :disabled="reserve_IsLoading"
                  @click="$emit('closeDialog')"
                  >Close</v-btn
                >
              </v-container>
            </v-row>
          </v-col>
          <v-col>
            <v-row no-gutters>
              <iframe
                :src="deskCalendarSource"
                title="Calendar"
                class="calendarView"
              >
              </iframe>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-snackbar
        v-model="alert_IncompleteForm"
        color="warning"
        :timeout="snackbarTimeout"
      >
        Date and Time need to be filled out to reserve a seat!
      </v-snackbar>
      <v-snackbar
        v-model="alert_SuccessfulReservation"
        color="success"
        :timeout="snackbarTimeout"
      >
        Reservation has been set for {{ FormatedDate(startDate) }}
      </v-snackbar>
      <v-snackbar
        v-model="alert_FailedReservation"
        color="error"
        :timeout="snackbarTimeout"
      >
        There was an issue when processing your reservation. Please try again
        later.
      </v-snackbar>
      <v-snackbar
        v-model="alert_WeekendSelected"
        color="warning"
        :timeout="snackbarTimeout"
        >Please select a work day.</v-snackbar
      >
      <v-snackbar
        v-model="alert_DateNotAvailable"
        color="error"
        :timeout="snackbarTimeout"
        >
        The requested time(s) are not avaiable for this desk.</v-snackbar
      >
      <v-card-actions> </v-card-actions>
    </v-card>
</template>

<script>
import graphApiWrapper from "@/graphApiWrapper";
import { mapGetters } from "vuex";

export default {
  name: "CalendarDialog",
  props: ["calendarSettings", "showDialog"],

  data: () => ({
    startDate: "",
    endDate: "",
    minDate: new Date().toISOString().slice(0, 10),
    startTime: null,
    endTime: null,
    timeStartInput: false,
    timeEndInput: false,
    reservationStartInput: false,
    reservationEndInput: false,
    isAllDay: false,
    isMorningOnly: false,
    isAfternoonOnly: false,
    presetTime: false,
    reserve_IsLoading: false,
    alert_IncompleteForm: false,
    alert_SuccessfulReservation: false,
    alert_FailedReservation: false,
    alert_WeekendSelected: false,
    alert_DateNotAvailable: false,
    snackbarTimeout: 3000,
  }),
  methods: {
    CheckAvailability: async function (dropInSeat) {
      const graph = new graphApiWrapper();
      let reservationTime = this.CleanDates();
      let type = ''
      if(this.isAllDay)
        type = 'allDay'
      else if(this.isMorningOnly)
        type = 'morning'
      else if(this.isAfternoonOnly)
        type ='afternoon'

      let isSeatAvailable = await graph.checkDropInAvailability(
        type,
        reservationTime[0],
        reservationTime[1],
        dropInSeat
      );
      return isSeatAvailable
    },
    validateReservation: async function () {

        let available = await this.CheckAvailability(this.calendarSettings.selectedDesk.podLocation)
        if (this.isFormFilledOut == false) {
          this.alert_IncompleteForm = true;
          return false;
        }

        else if ( this.isWeekendSelected == true ) {
          this.alert_WeekendSelected = true;
          return false;
        }

        else if (!available)
        {
          this.alert_DateNotAvailable = true
          return false;
        }
        else
          return true;
    },
    ReserveRoom: async function () {
      
        let validation = await this.validateReservation()
        if(validation == false) {
          this.alert_DateNotAvailable = true
          return
        }
        
        let reservationTime = this.CleanDates();
        const msGraph = new graphApiWrapper();
        this.reserve_IsLoading = true;

        try {
          msGraph.reserveDropInSeat(
            reservationTime[0],
            reservationTime[1],
            this.calendarSettings.selectedDesk.podLocation,
            this.user.name
          );
        } catch (error) {
          this.alert_FailedReservation = true;
          console.log(error);
          return;
        } finally {
          this.reserve_IsLoading = false;
        }
        this.alert_SuccessfulReservation = true;
    },
    CleanDates: function () {
      let reservationStartTime;
      let reservationEndTime;

      if (this.isAllDay) {
        reservationStartTime = 8;
        reservationEndTime = 17;
      } else if (this.isMorningOnly) {
        reservationStartTime = 8;
        reservationEndTime = 12;
      } else if (this.isAfternoonOnly) {
        reservationStartTime = 12;
        reservationEndTime = 17;
      }

      let start = new Date(this.startDate);
      start.setDate(start.getDate() + 1);
      start.setHours(reservationStartTime);
      start = start.toLocaleString();

      let end = new Date(this.endDate);
      end.setDate(end.getDate() + 1);
      end.setHours(reservationEndTime);
      end = end.toLocaleString();

      if (this.DateWeekendCheck(start) || this.DateWeekendCheck(end)) {
        this.alert_WeekendSelected = true;
        return;
      }

      return [start, end];
    },
    PresetTimeSelected: function (time) {
      switch (time) {
        case "AllDay":
          this.isMorningOnly = false;
          this.isAfternoonOnly = false;
          break;
        case "Morning":
          this.isAllDay = false;
          this.isAfternoonOnly = false;
          break;
        case "Afternoon":
          this.isAllDay = false;
          this.isMorningOnly = false;
          break;
      }
    },
    FormatedDate: function (date) {
      if (date === "" || date === null) {
        return;
      }

      let currentDate = new Date(this.startDate);
      currentDate.setDate(currentDate.getDate() + 1);
      return (currentDate = currentDate.toDateString());
    },
    DateWeekendCheck(dateString) {
      if (dateString === null || dateString === "") {
        return;
      }

      const date = new Date(dateString);
      var dayOfWeek = date.getDay();
      return dayOfWeek === 6 || dayOfWeek === 0;
    },
  },
  watch: {
    startDate(newVal) {
      this.endDate = newVal;
    },
  },
  computed: {
    ...mapGetters(["user"]),
    enableTimePicker: function () {
      return this.isAllDay || this.isMorningOnly || this.isAfternoonOnly;
    },
    endDateMin: function () {
      return this.startDate === null || this.startDate === ""
        ? this.minDate
        : new Date(this.startDate).toISOString().slice(0, 10);
    },
    isFormFilledOut: function () {
      return this.startDate && this.endDate && this.enableTimePicker;
    },
    
    isWeekendSelected: function () {
      if ( this.DateWeekendCheck(this.startDate) || this.DateWeekendCheck(this.endDate))
        return true
      else
        return false
    },
    deskCalendarSource: function () {
      if (this.calendarSettings.selectedDesk.podLocation == "Drop In Desk 1")
        return "https://outlook.office365.com/owa/calendar/2a339af6cdc248ce846249a4c3d8aa1b@omnitech-inc.com/933f0fb777d24f1e80114ea922530ab013818272853296444502/calendar.html";
      else if (this.calendarSettings.selectedDesk.podLocation == "Drop In Desk 2")
        return "https://outlook.office365.com/owa/calendar/a1e3ab5dbd004eabb3c5d2a537caad41@omnitech-inc.com/55ee886c42934ce285959c0b689a2f6e17329289457900400551/calendar.html";
      else
        return "https://outlook.office365.com/owa/calendar/29067d99534342bd98a18a762540e41a@omnitech-inc.com/9e6df7baed174128a588b1ee7c58591f14143656796952435729/calendar.html";
    },
  },
};
</script>

<style>
.calendarMenu {
  z-index: 100;
  position: absolute;
  min-width: 700px;
  height: 100%;
}

.calendarView {
  width: 100%;
  height: 600px;
}

.presetButton {
  padding-left: 15px;   
  padding-right: 15px;
}

.btns {
  margin: 10px;
  padding-bottom: 15px;
}

</style>