<template>
  <div>
      <div class="reservationModal">
        <DropInCalendarDialog class="reservationModal" v-if="showCalendarDialog" @closeDialog="closeCalendarDialog()"
        :calendar-settings="dropInCalendarSettings" >
        </DropInCalendarDialog> 
      </div>
    <div class="d-flex justify-start">
      <div class="d-flex flex-column">
        <v-card v-for="seat in layouts"
          :key="seat.officeAbreviation">
          <h1 class="chartTitle">{{seat.officeName}} Office</h1>
          <main class="wrapper">
            <div
              v-for="desk in deskLayout.filter(s => s.office === seat.officeAbreviation)"
              :key="desk.id"
              :class="[isInDropInRoom(desk.podLocation) ? 'desk dropIn': 'desk']"
              :id="`desk-${desk.id}`"
              @click="[isInDropInRoom(desk.podLocation) ? openCalendarDialog(desk): function() {}]"
              :style="`left: ${desk.xCoordinate}px; top: ${desk.yCoordinate}px; width: ${desk.width}px; height: ${desk.height}px `"
            >              
            <p
                v-if="employeeName(getDeskLabel(desk.id)) == ''"
                class="deskLabel unoccupiedDesk"
                :id="'Room'"
              >
                {{ desk.podLocation }}
              </p>
              <p
                v-else-if="getDeskPlacementType(desk.id) == 1"
                class="deskLabel"
                :id="getDeskLabel(desk.id)"
              >
                {{ employeeName(getDeskLabel(desk.id)) }}
              </p>
              <p
                v-else-if="getDeskPlacementType(desk.id) == 3"
                class="deskLabel"
                :id="'Meeting'"
              >
                {{ desk.podLocation }}
              </p>
              <p
                v-else-if="getDeskPlacementType(desk.id) == 2"
                class="deskLabel"
                :id="getDeskLabel(desk.id)"
              >
                {{ employeeName(getDeskLabel(desk.id)) }}
              </p>
              <p
                v-else-if="getDeskPlacementType(desk.id) == 4"
                class="deskLabel"
                :id="'Office'"
              >
                {{ "Drop In" }}
              </p>
              <p
                v-else-if="getDeskPlacementType(desk.id) == 5"
                class="deskLabel"
                :id="'FrontDesk'"
              >
                {{ "Front Desk" }}
              </p>
              <p
                v-if="getDeskPlacementType(desk.id) == 'NULL'"
                class="deskLabel unoccupiedDesk"
                :id="'Office'"
              >
                {{ desk.podLocation }}
              </p>
            </div>
          </main>
        </v-card>
      </div>
        <v-card
          class="unassignedNamesList"
          id="unassignedNamesSidebar"
        >
          <h1 class="OutOFOfficeTitle">Not Assigned</h1>
          <p
            v-for="name in unassignedNames"
            :key="name.id"
            class="deskLabel"
            :id="name.id"
          >
            {{ name.fullName }}
          </p>
        </v-card>
    </div>
    </div>
  
</template>

<script>
import seatingChartDataAccess from "../../../dataAccess/seatingChartDataAccess"
import DropInCalendarDialog from "../components/DropInRoomCalendar"
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    DropInCalendarDialog 
  },
  data: function () {
    return {
      layouts: [
        {officeName: 'Sioux Falls',
         officeAbreviation: 'SF'},
        {officeName: 'Rapid City',
         officeAbreviation: 'RC'},
      ],
      deskLayout: [],
      employees: [],
      currentFloorplan: null,
      seatingChartDAO: new seatingChartDataAccess(),
      showCalendarDialog: false,
      dropInCalendarSettings: {},
    };
  },
  computed: {
    ...mapGetters(["floorplanSeatsList", "user"]),
    unassignedNames() {
      return this.employees.filter(
        (e) => !this.selectedFloorplanSeatAssignments.find((d) => d.employeeId === e.id)
      );
    },
    selectedFloorplanSeatAssignments() {
      if (!this.currentFloorplan) {
        return [];
      }
      return this.floorplanSeatsList.filter(
        (fp) => {
          return fp.floorplanId === this.currentFloorplan.id;
        }
      );
    },
  },
  methods: {
    employeeName(employeeId) {
      if (employeeId === null) {
        return "";
      } else if (
        this.employees.find((e) => e.id === employeeId) === undefined
      ) {
        return "Drop In";
      } else {
        return this.employees.find((e) => e.id === employeeId).fullName;
      }
    },
    getDeskLabel(deskId) {
      let employee = this.selectedFloorplanSeatAssignments.find((e) => e.seatDetailsId === deskId)
      if(employee === undefined)
        return "NULL"
      else
        return employee.employeeId;
    },
    getDeskPlacementType(deskId) {
      let employee = this.selectedFloorplanSeatAssignments.find((e) => e.seatDetailsId === deskId)
      if(employee === undefined)
        return "NULL"
      else
        return employee.deskTypeId;
    },
    getShortenedUserName: function () {
        var userName = this.user.name;
        var indexAfterLastInitial = userName.indexOf(' ') + 2;
        var firstNameLastInitial = userName.substr(0, indexAfterLastInitial);
        firstNameLastInitial = firstNameLastInitial + '.'
        return firstNameLastInitial;
    },
    openCalendarDialog(desk) {
      this.$emit('toggleCalendarViewing');
      this.dropInCalendarSettings = {
          selectedDesk: desk, 
      }
      this.showCalendarDialog = true;
    },
    closeCalendarDialog() {
      this.$emit('toggleCalendarViewing');
      this.showCalendarDialog = false;
    },
    isInDropInRoom(deskPodName) {
          if (deskPodName.search("Drop In") >= 0) {
            return true;
        }
        return false;
    },    
  },
  mounted: async function () {
    this.deskLayout = await this.seatingChartDAO.getSeatingLayout();
    this.employees = await this.seatingChartDAO.getAllUsers();
    this.currentFloorplan = await this.seatingChartDAO.getCurrentFloorplan();
    if (this.currentFloorplan != null) {
      this.seatAssignments = await this.seatingChartDAO.getSeatAssignments(
        this.currentFloorplan.id
      );
    }
  },
};
</script>

<style>

html,
body {
  height: 100%;
  font-family: "Roboto";
}

</style>
<style scoped>

.v-card {
  margin: 1em;
}
.chartTitle {
  text-align: left;
  padding-left: 0.5em;
  margin-bottom: -0.5em;
}

.reservationModal {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.wrapper {
  position: relative;
  width: 1032px;
  height: 1165px;
}
.deskLabel {
  vertical-align: sub;
  margin: 16px 0px;
  padding: 2px 4px;
  text-align: center;
}

.desk {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  outline: solid;
}

.unoccupiedDesk {
  color:rgba(0, 0, 0, 0.4)
}

.unassignedNamesList {
  position: sticky;
  top: 1em;
  height: 671px;
  width: 250px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 15px;
  overflow-y: auto;
}
.OutOFOfficeTitle {
  font-size: 20px;
  text-align: center;
}

.dropIn {
  background-color: #a593c9;
}

.dropIn:hover {
  font-weight: bolder;
  cursor: pointer
}
</style>


